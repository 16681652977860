@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5); /* Scrollbar color */
    border-radius: 10px; /* Rounded corners for scrollbar */
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1); /* Background of the track */
  }

  @keyframes spiral-glow {
    0% {
      transform: scale(0) rotate(0deg);
      opacity: 1;
    }
    25% {
      transform: scale(0.5) rotate(90deg);
      opacity: 0.8;
    }
    50% {
      transform: scale(1) rotate(180deg);
      opacity: 0.6;
    }
    75% {
      transform: scale(1.5) rotate(270deg);
      opacity: 0.4;
    }
    100% {
      transform: scale(2) rotate(360deg);
      opacity: 0;
    }
  }
  
  

